/* You can add global styles to this file, and also import other style files */
:root {
  font-size: 14px;
  --ip-green: #157065;
  --ip-light-green: #008a7b;
  --ip-pink: #f60a6d;
  --ip-grey: #828282;
  --ip-light-blue: #d7fffa;
  --theme-green: #157065;
  --theme-green-dark: #0b544c;
  /* --theme-green:#74b1a4; */
}
body {
  padding: 0px;
  margin: 0px;
}
figure {
  padding: 0px;
  margin: 0px;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.flex-align-center {
  align-items: center;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.text-center {
  text-align: center;
}
.display-inline {
  display: inline !important;
}
.no-text-decoration {
  text-decoration: none;
}
.has-bg-white {
  background: white;
}
.hidden {
  display: none;
}
.display-mobile-only {
  display: none;
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 8;
  display: none;
}
@media only screen and (max-width: 748px) {
  .display-mobile-only {
    display: initial;
  }
  .display-desktop-only {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
